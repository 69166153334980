import React from 'react';
import images from 'src/assets/images';

interface StatsProps {
  lifetimeDonations: number;
  fundcoinsGenerated: number;
}

const ProfileStats: React.FC<StatsProps> = ({
  lifetimeDonations,
  fundcoinsGenerated
}) => {
  return (
    <div className="flex justify-around mt-6 bg-[#0A1E32] text-white py-6 rounded-xl w-11/12 mx-auto">
      <div className="text-center">
        <h3 className="text-2xl font-normal">{`$${lifetimeDonations.toFixed(2)}`}</h3>
        <p className="text-xs text-[#8197AD]">Lifetime Donations</p>
      </div>
      <div className="text-center">
        <div className="flex items-center justify-center">
          <img src={images.fund_coin_icon} alt="Fundcoin Icon" className="w-5 h-5 mr-2" />
          <h3 className="text-2xl font-normal">{fundcoinsGenerated}</h3>
        </div>
        <p className="text-xs text-[#8197AD]">Fundcoin generated</p>
      </div>
    </div>
  );
};

export default ProfileStats;

import React from 'react';
import images from 'src/assets/images'; // Make sure to replace this with the correct path to your assets

interface FundcoinRedeemCardProps {
  coinAmount: number;
  onRedeemNow: () => void;
  disabled?: boolean; 
}

const FundcoinRedeemCard: React.FC<FundcoinRedeemCardProps> = ({ coinAmount, onRedeemNow, disabled }) => {
  return (
    <div className="flex justify-between items-center bg-[#0A1E32] p-8 rounded-t-2xl w-full shadow-md">
      <button
        onClick={onRedeemNow}
        disabled={disabled}
        className={`w-full flex items-center justify-center bg-gradient-to-r from-pink-500 to-orange-400 text-white text-md py-4 rounded-xl shadow-md whitespace-nowrap ${
          disabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        Redeem Reward
      </button>
    </div>
  );
};

export default FundcoinRedeemCard;

import React, { useState, useEffect } from 'react';
import BottomTabsBar from 'src/components/common/bottomTabsBar';
import SliderWrapper from 'src/components/slider/sliderWrapper';
import UserRewardCard from 'src/components/dashboard/userRewardCard';
import FundcoinBalanceCard from 'src/components/dashboard/fundBalanceCard';
import { fetchPopularRewards, fetchUserProfile } from 'src/services/dashboard/api';
import RewardCard from 'src/components/reward/rewardCard';
import { useAuth } from 'src/contexts/authContext';
import RouteCard from 'src/components/wallet/routeCard';
import images from 'src/assets/images';
import { useNavigate } from 'react-router-dom';

type Reward = {
  id: string;
  name: string;
  fundcoinCost: number;
  imageUrl: string;
  rewardId: number;
  CategoryName: string;
  buyersCount: number;
  profilePictures: string[];
  color: string
};

type PopularReward = Reward;

const Wallet: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [popularRewards, setPopularRewards] = useState<PopularReward[]>([]);
  const [userRewards, setUserRewards] = useState<PopularReward[]>([]);
  const colors = ['#00BCD5', '#FF8490', '#FFA674'];
  const [fundCoinBalance, setFundCoinBalance] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfileData: any = await fetchUserProfile({ userId: user.id });
        setUserRewards(userProfileData.rewards);
        console.log("rewards data", userRewards)
        const popularRewardsData: any = await fetchPopularRewards();
        setPopularRewards(popularRewardsData);
        setFundCoinBalance(user.fundcoinBalance || 0);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [user.id]);

  const handleRewardClick = (rewardId: string) => {
    navigate(`/reward-detail/${rewardId}`); 
  };

  const handleSpendFundcoinClick = () => {
    navigate('/rewards'); 
  };

  const handleGetMoreFundcoinClick = () => {
    navigate('/refer'); 
  };

  return (
    <>
      <div className="px-4 mt-4">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Wallet</h1>
        </div>
      </div>

      <div className="flex-grow px-4 overflow-y-auto overflow-x-hidden scrollbar-hide pb-20">
        <div className="mt-4 space-y-4">
          <FundcoinBalanceCard balance={fundCoinBalance} nextDepositDate="December 1st, 2023" showButton={false} />

          <div className="flex space-x-2">
            <RouteCard
              title="Spend Fundcoin"
              buttonText="Go to Rewards"
              onClick={handleSpendFundcoinClick} 
              cardBackgroundColor="bg-[#3D86FF]"
              buttonBackgroundColor="bg-[#0A1E32] bg-opacity-30"
              image={images.reward_icon}
            />
            <RouteCard
              title="Get more Fundcoin"
              buttonText="Earn more"
              onClick={handleGetMoreFundcoinClick} 
              cardBackgroundColor="bg-gradient-to-r from-pink-500 to-orange-400"
              buttonBackgroundColor="bg-[#0A1E32]"
              image={images.fund_coin_icon}
              buttonIcon={images.fund_coin_icon}
            />
          </div>
        </div>

        <div className="mt-8 w-full">
          <h2 className="text-lg font-bold text-gray-900 ml-2">My Rewards</h2>
          <div className="relative rounded-xl w-full mt-2 shadow-md shadow-[0px_4px_10px_rgba(0,0,0,0.2)]">
            {userRewards.length > 0 ? (
              <SliderWrapper
                items={userRewards}
                renderItem={(userReward, index) => (
                  <UserRewardCard
                    key={index}
                    imageUrl={userReward.imageUrl}
                    category={userReward.RewardCategory.name}
                    title={userReward.name}
                    onClick={() => handleRewardClick(userReward.id)}
                  />
                )}
              />
            ) : (
              <p className="text-center text-[#787B7E] mt-10">No user rewards available</p>
            )}
          </div>
        </div>

        <div className="mt-12 w-full">
          <h2 className="text-lg font-bold text-gray-900 ml-2">Popular Rewards</h2>
          <div className="grid gap-4 mt-4">
            {popularRewards.length > 0 ? (
              popularRewards.map((popularReward, index) => (
                <RewardCard
                  key={index}
                  imageUrl={popularReward.imageUrl}
                  category={popularReward.CategoryName}
                  title={popularReward.name}
                  coinAmount={popularReward.fundcoinCost}
                  buyersCount={popularReward.buyersCount || 0}
                  buyersImages={popularReward.profilePictures || []}
                  backgroundColor={popularReward.color || '#00BCD5'}
                  onClick={() => handleRewardClick(popularReward.id)}
                />
              ))
            ) : (
              <p className="text-center text-[#787B7E] mt-10">No popular rewards available</p>
            )}
          </div>
        </div>

        <div className="mt-8 w-full">
          <div className="w-full h-40 bg-gray-100 rounded-lg"></div>
        </div>
      </div>

      <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto">
        <BottomTabsBar />
      </div>
    </>
  );
};

export default Wallet;

import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import images from 'src/assets/images';

const ReferCard: React.FC = () => {
  const navigate = useNavigate(); 

  const handleGetRewardClick = () => {
    navigate('/refer'); 
  };

  return (
    <div className="relative w-full h-16 rounded-2xl bg-gradient-to-r from-pink-500 to-orange-400 px-4 py-2 shadow-md flex items-center">
      <div className="flex flex-col justify-center flex-1"> 
        <div className="text-white text-md font-bold">Invite a friend</div>
        <div className="text-white text-sm"> & get 50 fundcoin</div>
      </div>
      <div className="flex-shrink-0"> 
        <button 
          className="bg-[#0A1E32] text-white rounded-xl px-2 py-2 flex items-center space-x-2"
          onClick={handleGetRewardClick} 
        >
          <span className="font-bold">Get</span> 
          <img
            src={images.fund_coin_icon}
            alt="Fundcoin Icon"
            className="w-4 h-4"
          />
          <span className="font-bold">50 Now</span>
        </button>
      </div>
    </div>
  );
};

export default ReferCard;

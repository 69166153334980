import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import images from 'src/assets/images';

interface FundcoinBalanceCardProps {
  balance: number;
  nextDepositDate?: string; 
  showButton?: boolean; 
}

const FundcoinBalanceCard: React.FC<FundcoinBalanceCardProps> = ({ balance, nextDepositDate, showButton = true }) => {
  const navigate = useNavigate(); 

  const handleViewClick = () => {
    navigate('/wallet'); 
  };

  return (
    <div className="relative w-full rounded-2xl bg-[#0A1E32]">
      <div className="p-4">
        <div className="flex justify-between items-center">
          <div className="flex flex-col justify-start">
            <div className="text-xs font-semibold mb-1" style={{ color: '#8298AE' }}>
              FUNDCOIN BALANCE
            </div>
            <div className="flex items-center space-x-2">
              <img src={images.fund_coin_icon} alt="Fundcoin Icon" className="w-8 h-8" />
              <div className="text-4xl font-bold text-white">{balance.toLocaleString()}</div>
            </div>
          </div>
          {showButton && (
            <button
              className="rounded-md px-4 py-2 font-semibold bg-[#102a43]"
              onClick={handleViewClick} 
            >
              <span
                className="text-transparent bg-clip-text"
                style={{
                  background: 'linear-gradient(to right, #EC4899, #FB923C)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                View
              </span>
            </button>
          )}
        </div>
      </div>

      {nextDepositDate && (
        <div className="w-full bg-[#102a43] rounded-b-2xl px-4 py-1">
          <div className="text-md text-[#8298AE]">
            Next Fundcoin Deposit:{' '}
            <span
              className="text-transparent bg-clip-text"
              style={{
                background: 'linear-gradient(to right, #EC4899, #FB923C)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {nextDepositDate}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FundcoinBalanceCard;

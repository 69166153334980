import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileInfo from 'src/components/profile/profileInfo';
import ProfileStats from 'src/components/profile/profileStats';
import DonationSection from 'src/components/profile/donationSection';
import images from 'src/assets/images';
import { useAuth } from 'src/contexts/authContext';
import { uploadProfileImage } from 'src/services/profile/api';
import { fetchUserProfile } from 'src/services/dashboard/api';

type CauseSubscriptionWithCause = {
  causeId: number;
  amount: string;
  status: string;
  fundcoinsEarned: number;
  "Cause.id": number;
  "Cause.name": string;
  "Cause.goalAmount": number;
  "Cause.currentAmount": number;
  "Cause.causeProfileUrl": string;
  "Cause.imageUrl": string;
  "Cause.mission": string;
  "Cause.description": string;
};

const Profile: React.FC = () => {
  const { user, updateUser } = useAuth();
  const [causes, setCauses] = useState([]);
  const [causesDonation, setCausesDonation] = useState([]);
  const [totalDonations, setTotalDonations] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    handleUserDetailFetch();
  }, []);

  const handleUserDetailFetch = async () => {
    const userProfileData: any = await fetchUserProfile({ userId: user.id });
    setCauses(userProfileData.causes || []);
    setTotalDonations(userProfileData.totalDonations || 0);
    setCausesDonation(userProfileData.userAllCausesSubscriptions || []);
  };

  const handleBack = () => {
    navigate('/settings'); // Adjust to the correct route
  };

  const handleUploadClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.onchange = async (event: any) => {
      const file = event.target.files[0];
      if (file) {
        try {
          const formData = new FormData();
          formData.append('profileImage', file);
          formData.append('userId', user.id);

          const response: any = await uploadProfileImage(formData);

          if (response) {
            console.log('Profile image uploaded successfully:', response.imageUrl);
            await updateUser({ userId: user.id });
          } else {
            console.error('Failed to upload profile image:');
          }
        } catch (error) {
          console.error('Error uploading profile image:', error);
        }
      }
    };

    fileInput.click();
  };

  return (
    <div className="relative h-screen overflow-hidden">
      <div className="absolute top-6 flex justify-between items-center w-full px-4">
        <img
          src={images.leftarrow}
          alt="Back"
          className="w-4 h-4 cursor-pointer mt-3"
          onClick={handleBack}
        />
        <div className="flex-grow flex justify-center">
          <h1 className="text-lg font-bold mt-2">Profile</h1>
        </div>
      </div>

      {/* Scrollable content container */}
      <div className="py-12 overflow-y-auto h-full px-4 pb-20 scrollbar-hide">
        <ProfileInfo
          profileImage={user.profileUrl || images.profile}
          firstName={user.firstName}
          lastName={user.lastName}
          joinDate={user.joinDate}
          onUploadClick={handleUploadClick} // Pass the upload function to ProfileInfo
        />

        <ProfileStats
          lifetimeDonations={totalDonations}
          fundcoinsGenerated={totalDonations * 15}
        />

        {causesDonation?.length > 0 ? (
          causesDonation.map((cause: CauseSubscriptionWithCause) => (
            <DonationSection
              key={cause.causeId}
              donationAmount={cause.amount}
              fundcoinEarned={cause.fundcoinsEarned}
              causeLogo={cause['Cause.causeProfileUrl']}
              causeName={cause['Cause.name']}
              causeId={cause.causeId}
              subscriptionStatus={cause.status}
            />
          ))
        ) : (
          <p className="text-center text-[#787B7E] mt-4">No donations found</p>
        )}
      </div>
    </div>
  );
};

export default Profile;
